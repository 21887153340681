import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import bcrypt from "bcryptjs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LogContext from "../context/LogContext";

const EditMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    memberId,
    memberName,
    memberMail,
    phone,
    birthdayDate,
    managerId,
    departmentId,
    address,
    memberRemainingLeaveTime,
    memberStartWorkDate
  } = location.state;
  const [valueDepartment, setValueDepartment] = React.useState([]);
  const [valueManager, setValueManager] = React.useState([]);
  const [value, setValue] = React.useState(
    moment(moment(birthdayDate, "DD-MM-YYYY"), "MM-DD-YYYY")
  );
  const [selectedDepartmentId, setSelectedDepartmentId] =
    React.useState(departmentId);
  const [selectedManagerId, setSelectedManagerId] = React.useState(managerId);
  const [newBirthday, setNewBirthday] = React.useState(null);
  const [startWorkDate, setStartWorkDate] = React.useState(moment(moment(memberStartWorkDate, "DD-MM-YYYY"), "MM-DD-YYYY"));
  const [newStartWorkDate, setNewStartWorkDate] = React.useState(null);
  const onSelectedDepartment = (e) => {
    setSelectedDepartmentId(parseInt(e.getAttribute("data-value")));
  };
  const onSelectedManager = (e) => {
    setSelectedManagerId(parseInt(e.getAttribute("data-value")));
  };
  const [values, setValues] = React.useState({
    showPassword: false,
    newPassword: "",
  });

  const [formValue, setFormValue] = useState({
    email: memberMail,
    username: memberName,
    phoneNumber: phone,
    userAddress: address,
    remainingLeaveTime: memberRemainingLeaveTime
  });
  const [editMemberLog, setEditMemberLog] = useContext(LogContext);
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetDepartment`, {
        responseType: "json",
      })
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setValueDepartment(res.data.Department);
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetManager`, {
        responseType: "json",
      })
      .then(function (response) {
        if (
          response.data.Status === true &&
          response.data.StatusCode === "OK"
        ) {
          setValueManager(response.data.Member);
        }
      });
  }, []);
  const [checked, setChecked] = React.useState(false);
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const { email, username, phoneNumber, userAddress, remainingLeaveTime } = formValue;
  const onchangeSwitch = (e) => {
    setChecked(e.target.checked);
    setValues({
      newPassword: "",
    });
  };
  const handleSubmit = () => {
    if (checked === true) {
      if (
        formValue.username !== "" &&
        formValue.phoneNumber !== "" &&
        formValue.email !== "" &&
        formValue.userAddress !== "" &&
        selectedDepartmentId !== 0 &&
        selectedManagerId !== 0 &&
        value !== null &&
        values.newPassword !== "" &&
        formValue.remainingLeaveTime !== "" &&
        startWorkDate !== null
      ) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/Member/UpdateMember`, {
            MemberMail: formValue.email,
            MemberName: formValue.username,
            PhoneNumber: formValue.phoneNumber,
            Address: formValue.userAddress,
            ManagerId: selectedManagerId,
            BirthdayDate:
              newBirthday !== null
                ? newBirthday
                : moment.utc(value).local().format(),
            DepartmentId: selectedDepartmentId,
            MemberId: memberId,
            MemberPassword: bcrypt.hashSync(
              values.newPassword,
              "$2a$10$CwTycUXWue0Thq9StjUM0u"
            ),
            StartWorkDate: newStartWorkDate !== null ? newStartWorkDate : moment.utc(startWorkDate).local().format(),
            RemainingLeaveTime: (formValue.remainingLeaveTime).toString().includes(",") === true ? parseFloat((formValue.remainingLeaveTime).replace(/,/g, '.')) : parseFloat(formValue.remainingLeaveTime)
          })
          .then((res) => {
            if (res.data.Status === true) {
              setEditMemberLog(true)
              Swal.fire({
                icon: "success",
                title: "Kullanıcı Düzenleme İşlemi Başarılı",
              }).then((result) => {
                navigate("/MemberTransactions");
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: res.data.StatusCode,
              }).then((result) => { });
            }
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    } else {
      if (
        formValue.username !== "" &&
        formValue.phoneNumber !== "" &&
        formValue.email !== "" &&
        formValue.userAddress !== "" &&
        selectedDepartmentId !== 0 &&
        selectedManagerId !== 0 &&
        value !== null &&
        formValue.remainingLeaveTime !== "" &&
        startWorkDate !== null
      ) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/Member/UpdateMember`, {
            MemberMail: formValue.email,
            MemberName: formValue.username,
            PhoneNumber: formValue.phoneNumber,
            Address: formValue.userAddress,
            ManagerId: selectedManagerId,
            BirthdayDate:
              newBirthday !== null
                ? newBirthday
                : moment.utc(value).local().format(),
            DepartmentId: selectedDepartmentId,
            MemberId: memberId,
            MemberPassword: null,
            StartWorkDate: newStartWorkDate !== null ? newStartWorkDate : moment.utc(startWorkDate).local().format(),
            RemainingLeaveTime: (formValue.remainingLeaveTime).toString().includes(",") === true ? parseFloat((formValue.remainingLeaveTime).replace(/,/g, '.')) : parseFloat(formValue.remainingLeaveTime)
          })
          .then((res) => {
            if (res.data.Status === true) {
              setEditMemberLog(true)
              Swal.fire({
                icon: "success",
                title: "Kullanıcı Düzenleme İşlemi Başarılı",
              }).then((result) => {
                navigate("/MemberTransactions");
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: res.data.StatusCode,
              }).then((result) => { });
            }
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],

        flexGrow: 1,
        height: "auto",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Card>
          <CardBody>
            <div className="edit-form">
              <div style={{ width: "100%" }}>
                <IconButton
                  className="float-left"
                  onClick={() => navigate("/MemberTransactions")}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              </div>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "20px", sm: "25px" },
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                Kullanıcı Düzenle
              </Typography>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="username"
                      label="Ad Soyad "
                      name="username"
                      onChange={handleChangeForm}
                      value={username}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="email"
                      label="E-mail "
                      name="email"
                      onChange={handleChangeForm}
                      value={email}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="phoneNumber"
                      label="Telefon "
                      name="phoneNumber"
                      onChange={handleChangeForm}
                      value={phoneNumber}
                      inputProps={{ maxLength: 11 }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Doğum Tarihi "
                        id="date"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                          setNewBirthday(
                            moment(newValue?.$d).format("YYYY-MM-DD")
                          );
                        }}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      id="department"
                      select
                      label="Departman"
                      value={selectedDepartmentId}
                    >
                      {valueDepartment.map((option) => (
                        <MenuItem
                          key={option.DepartmentId}
                          value={option.DepartmentId}
                          onClick={(e) => onSelectedDepartment(e.target)}
                        >
                          {option.DepartmentName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Yetkili"
                      name="manager"
                      value={selectedManagerId}
                    >
                      {valueManager.map((option) => (
                        <MenuItem
                          key={option.MemberId}
                          value={option.MemberId}
                          onClick={(e) => onSelectedManager(e.target)}
                        >
                          {option.MemberName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="İşe Giriş Tarihi "
                        value={startWorkDate}
                        onChange={(newValue) => {
                          setStartWorkDate(newValue);
                          setNewStartWorkDate(
                            moment(newValue?.$d).format("YYYY-MM-DD")
                          );
                        }}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                    <TextField
                      id="remainingLeaveTime"
                      label="Kalan İzin"
                      name="remainingLeaveTime"
                      value={remainingLeaveTime}
                      onChange={handleChangeForm}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      id="userAddress"
                      label="İkametgah Adresi"
                      name="userAddress"
                      multiline
                      maxRows={3}
                      value={userAddress}
                      onChange={handleChangeForm}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={onchangeSwitch} />
                    }
                    label="Yeni Şifre Oluşturma"
                  />
                  {checked === true && (
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <InputLabel>Şifre</InputLabel>
                      <OutlinedInput
                        id="newPassword"
                        name="newPassword"
                        type={values.showPassword ? "text" : "password"}
                        value={values.newPassword}
                        onChange={handleChange("newPassword")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="password"
                      />
                      <br></br>
                    </FormControl>
                  )}
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="form-group col-sm-12">
                  <Button variant="primary" onClick={handleSubmit}>
                    Kaydet
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Box>
  );
};

export default EditMember;
