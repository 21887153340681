import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import Swal from "sweetalert2";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import bcrypt from "bcryptjs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const AddMember = () => {
  const [value, setValue] = React.useState(null);
  const [startWorkDate, setStartWorkDate] = React.useState(null);
  const [newBirthday, setNewBirthday] = React.useState(null);
  const [newStartWorkDate, setNewStartWorkDate] = React.useState(null);
  const [values, setValues] = React.useState({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    address: "",
    showPassword: false,
  });
  const [valueDepartment, setValueDepartment] = React.useState([]);
  const [valueManager, setValueManager] = React.useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState(0);
  const [selectedManagerId, setSelectedManagerId] = React.useState(0);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function randomPassword(length) {
    var chars =
      "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    values.password = pass;
  }
  useEffect(() => {
    randomPassword(5);
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetDepartment`, {
        responseType: "json",
      })
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setValueDepartment(res.data.Department);
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetManager`, {
        responseType: "json",
      })
      .then(function (response) {
        if (
          response.data.Status === true &&
          response.data.StatusCode === "OK"
        ) {
          setValueManager(response.data.Member);
        }
      });
  }, []);

  const navigate = useNavigate();
  const onSelectedDepartment = (e) => {
    setSelectedDepartmentId(parseInt(e.getAttribute("data-value")));
  };
  const onSelectedManager = (e) => {
    setSelectedManagerId(parseInt(e.getAttribute("data-value")));
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      values.name !== "" &&
      newBirthday !== null &&
      values.password !== "" &&
      values.phoneNumber !== "" &&
      values.email !== "" &&
      values.address !== "" &&
      selectedDepartmentId !== 0 &&
      selectedManagerId !== 0 &&
      newStartWorkDate !== null
    ) {
      const hashedPassword = bcrypt.hashSync(
        values.password,
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      );

      axios
        .post(`${process.env.REACT_APP_API_URL}/Member/AddMember`, {
          MemberName: values.name,
          BirthdayDate: newBirthday,
          PhoneNumber: values.phoneNumber,
          MemberMail: values.email,
          MemberPassword: hashedPassword,
          DepartmentId: selectedDepartmentId,
          ManagerId: selectedManagerId,
          PlaintextPassword: values.password,
          Address: values.address,
          StartWorkDate: newStartWorkDate
        })
        .then((res) => {
          if (res.data.Status === true) {
            Swal.fire({
              icon: "success",
              title: "Kullanıcı Ekleme İşlemi Başarılı",
            }).then((result) => {
              navigate("/MemberTransactions");
              setValue(null);
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: res.data.StatusCode,
            }).then((result) => {
              setValue(null);
            });
          }
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tüm Alanları Doldurunuz!!",
        showConfirmButton: false,
      });
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],

        flexGrow: 1,
        height: "auto",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Card>
          <CardBody>
            <div style={{ width: "100%" }}>
              <IconButton
                className="float-left"
                onClick={() => navigate("/MemberTransactions")}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </div>
            <div>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "20px", sm: "25px" },
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                Kullanıcı Ekle
              </Typography>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="name"
                      label="Ad Soyad "
                      name="name"
                      onChange={handleChange("name")}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="email"
                      label="E-mail "
                      name="email"
                      onChange={handleChange("email")}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      type="text"
                      id="phoneNumber"
                      label="Telefon "
                      name="phoneNumber"
                      onChange={handleChange("phoneNumber")}
                      inputProps={{ maxLength: 11 }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Doğum Tarihi "
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                          setNewBirthday(
                            moment(newValue?.$d).format("YYYY-MM-DD")
                          );
                        }}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField id="departmant" select label="Departman">
                      {valueDepartment.map((option) => (
                        <MenuItem
                          key={option.DepartmentId}
                          value={option.DepartmentId}
                          onClick={(e) => onSelectedDepartment(e.target)}
                        >
                          {option.DepartmentName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField id="manager" select label="Yetkili">
                      {valueManager.map((option) => (
                        <MenuItem
                          key={option.MemberId}
                          value={option.MemberId}
                          onClick={(e) => onSelectedManager(e.target)}
                        >
                          {option.MemberName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      id="address"
                      label="İkametgah Adresi"
                      multiline
                      maxRows={3}
                      onChange={handleChange("address")}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="İşe Giriş Tarihi "
                        value={startWorkDate}
                        onChange={(newValue) => {
                          setStartWorkDate(newValue);
                          setNewStartWorkDate(
                            moment(newValue?.$d).format("YYYY-MM-DD")
                          );
                        }}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel>Şifre</InputLabel>
                    <OutlinedInput
                      id="password"
                      name="password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="password"
                    />
                    <br></br>
                  </FormControl>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="form-group col-sm-12">
                  <button
                    id="sub_btn"
                    type="submit"
                    className="btn-block"
                    style={{ backgroundColor: "grey" }}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Box>
  );
};

export default AddMember;
