import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
// import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

export default function MemberList() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [deleteMember, setDeleteMember] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Member/GetMemberList`, {})
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setRows(
            res.data.Member.map((member) => {
              return {
                ...member,
                BirthdayDate: moment(member.BirthdayDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                StartWorkDate: moment(member.StartWorkDate)
                  .format("DD-MM-YYYY")
                  .toString()
              };
            })
          );
        }
      });
  }, [deleteMember]);

  const useStyles = makeStyles((theme) => ({
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  const handleSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      if (key === "MemberName") {
        return a.MemberName.localeCompare(b.MemberName, "tr", {
          sensitivity: "base",
        });
      }
      if (key === "MemberMail") {
        return a.MemberMail.localeCompare(b.MemberMail, "tr", {
          sensitivity: "base",
        });
      }
      if (key === "BirthdayDate") {
        const dateA = new Date(a[key].split("-").reverse().join("-"));
        const dateB = new Date(b[key].split("-").reverse().join("-"));
        return dateA - dateB;
      }
      if (key === "PhoneNumber") {
        return a.PhoneNumber - b.PhoneNumber;
      }
      return 0;
    });

    if (direction === "desc") {
      sorted.reverse();
    }
    setRows(sorted);
  };
  const getArrowIcon = (key) => {
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      return <ArrowDropUpIcon sx={{ color: "green !important" }} />;
    }
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      return <ArrowDropDownIcon sx={{ color: "green !important" }} />;
    }
    return <span />;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredData = rows.filter((row) => {
    return (
      row.MemberName.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.MemberMail.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.BirthdayDate.includes(searchQuery) ||
      row.PhoneNumber.includes(searchQuery)
    );
  });

  const handleChangeSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleDeleteRow = (id) => {
    // const updatedData = rows.filter((row) => row.id !== id);
    const updatedData = rows.filter((row) => row.MemberId !== id);
    setRows(updatedData);
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <p align="left" style={{ fontSize: 25, padding: 20 }}>
          Kullanıcılar
        </p>

        <br></br>
        <Tooltip title="Kullanıcı Ekle">
          <IconButton
            className="float-right mr-4"
            onClick={() => navigate("/AddMember")}
          >
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
        <br></br>
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          label="Search"
          value={searchQuery}
          placeholder="Liste İçinde Ara"
          onChange={handleChangeSearch}
          variant="outlined"
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("MemberName")}
                >
                  <b>Ad Soyad </b>
                  {getArrowIcon("MemberName")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("MemberMail")}
                >
                  <b>E-Mail</b>
                  {getArrowIcon("MemberMail")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("PhoneNumber")}
                >
                  <b>Telefon</b>
                  {getArrowIcon("PhoneNumber")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("BirthdayDate")}
                >
                  <b>Doğum Tarihi</b>
                  {getArrowIcon("BirthdayDate")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                // getComparator(sortDirection, sortKey))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const memberId = row.MemberId;
                  const memberType = row.MemberType;
                  return (
                    <TableRow key={row.MemberId}>
                      {/* <TableRow key={row.id}> */}
                      <TableCell align="center">
                        {row.ImagePath === null ? (
                          <Avatar
                            alt="Profile Photo"
                            // src={`https://avatars.dicebear.com/api/initials/${row.MemberName}.svg`}
                            {...stringAvatar(`${row.MemberName}`)}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        ) :
                          <Avatar
                            alt="Profile Photo"
                            src={row.ImagePath}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        }
                      </TableCell>
                      <TableCell align="center">{row.MemberName}</TableCell>
                      <TableCell align="center">{row.MemberMail}</TableCell>
                      <TableCell align="center">{row.PhoneNumber}</TableCell>
                      <TableCell align="center">{row.BirthdayDate}</TableCell>
                      <>
                        <Tooltip title="Kullanıcı Sil">
                          <IconButton
                            onClick={() => {
                              if (memberType === 1) {
                                Swal.fire({
                                  icon: "warning",
                                  title: ` ${row.MemberName} Adlı Kullanıcı Yetkili Olduğundan Silinemez!!`,
                                });
                              } else {
                                Swal.fire({
                                  title: ` ${row.MemberName} Adlı Kullanıcıyı Silmek İstediğinizden Emin Misiniz?`,
                                  showDenyButton: true,
                                  confirmButtonText: "Evet",
                                  denyButtonText: "Hayır",
                                  customClass: {
                                    actions: "my-actions",
                                    cancelButton: "order-1 right-gap",
                                    confirmButton: "order-2",
                                    denyButton: "order-3",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setDeleteMember(0);
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_API_URL}/Member/DeleteMember`,
                                        {
                                          MemberId: memberId,
                                        }
                                      )
                                      .then((res) => {
                                        if (res.data.Status === true) {
                                          setDeleteMember(1);
                                          handleDeleteRow(memberId);
                                          Swal.fire({
                                            icon: "success",
                                            title:
                                              "Kullanıcı Silme İşlemi Başarılı",
                                          });
                                        } else {
                                          Swal.fire({
                                            icon: "warning",
                                            title: res.data.StatusCode,
                                          });
                                        }
                                      });
                                  }
                                });
                              }
                            }}
                          >
                            <PersonRemoveIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Kullanıcı Düzenle">
                          <IconButton onClick={() => { }}>
                            <Link
                              to="/EditMember"
                              state={{
                                memberId: memberId,
                                memberName: row.MemberName,
                                memberMail: row.MemberMail,
                                birthdayDate: row.BirthdayDate,
                                phone: row.PhoneNumber,
                                departmentId: row.DepartmentId,
                                managerId: row.ManagerId,
                                password: row.MemberPassword,
                                address: row.Address,
                                memberRemainingLeaveTime: row.RemainingLeaveTime,
                                memberStartWorkDate: row.StartWorkDate
                              }}
                            >
                              <EditIcon sx={{ color: "gray" }} />
                            </Link>
                          </IconButton>
                        </Tooltip>
                      </>
                    </TableRow>
                  );
                })}
            </TableBody>
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
