import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import Logo from "../assets/images/sadeteamlogo.png";
import PasswordReset from "./PasswordReset";
import React, { useContext, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Swal from "sweetalert2";
import AuthContext from "../context/AuthContext";
import { useCookies } from "react-cookie";
import bcrypt from "bcryptjs";
import LogContext from "../context/LogContext";
import $ from "jquery";

function LoginPage({ setMemberType }) {
  const [cookie, setCookie] = useCookies(["email", "pwd", "memberType"]);
  const [auth, setAuth] = useContext(AuthContext);
  const [visibleForgotPassword, setVisibleForgotPassword] = useContext(LogContext);

  useEffect(() => {
    const email = cookie.email;
    const password = cookie.pwd;
    const url = new URL(window.location.href);
    const urlEmail = url.searchParams.get('email')
    const urlUid = url.searchParams.get('uid')
    if (urlEmail !== null && urlUid !== null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/Login/FindGuid`, { //şifre resetleme için gönderilen linkten yönlendirme yapma
          MemberMail: urlEmail,
          MemberGUID: urlUid,
        })
        .then((res) => {
          if (res.data.Status === true) {
            if (res.data.Value === 1) {
              setVisibleForgotPassword(1);
            }
            else {
              setVisibleForgotPassword(0);
            }
          }
          else {
            setVisibleForgotPassword(0);
          }
        })
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/Login/Login`, { //cookie ile giriş yapma
        MemberMail: email,
        MemberPassword: password,
      })
      .then((res) => {
        if (res.data.Status === true) {
          const managerId = res.data.Member[0].ManagerId;
          const memberId = res.data.Member[0].MemberId;
          const birthday = res.data.Member[0].BirthdayDate;
          const phone = res.data.Member[0].PhoneNumber;
          const mail = res.data.Member[0].MemberMail;
          const memberName = res.data.Member[0].MemberName;
          const memberType = res.data.Member[0].MemberType;
          const IsManager = res.data.Member[0].IsManager;
          const password = res.data.Member[0].MemberPassword;
          setAuth({
            memberName: memberName,
            memberId: memberId,
            birthday: birthday,
            phone: phone,
            memberType: memberType,
            isManager: IsManager,
            memberMail: mail,
            managerId: managerId,
            memberPassword: password,
            windowWidth: $(window).width()
          });

          if (res.data.Member[0].MemberType === 0) {
            setMemberType(0);
          } else {
            setMemberType(1);
          }
        }
      });
  }, []);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    email: "",
  });

  const [passwordReset, setPasswordReset] = React.useState(false);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    var email = values.email;
    var pwd = values.password;
    const hashedPassword = bcrypt.hashSync(
      pwd,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    if (email !== "" && pwd !== "") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/Login/Login`, {
          MemberMail: email,
          MemberPassword: hashedPassword,
        })
        .then((res) => {
          if (res.data.Status === true) {
            const managerId = res.data.Member[0].ManagerId;
            const memberId = res.data.Member[0].MemberId;
            const birthday = res.data.Member[0].BirthdayDate;
            const phone = res.data.Member[0].PhoneNumber;
            const memberName = res.data.Member[0].MemberName;
            const IsManager = res.data.Member[0].IsManager;
            const MemberType = res.data.Member[0].MemberType;
            const mail = res.data.Member[0].MemberMail;
            const password = res.data.Member[0].MemberPassword;
            setAuth({
              memberName: memberName,
              memberId: memberId,
              birthday: birthday,
              phone: phone,
              memberType: MemberType,
              isManager: IsManager,
              memberMail: mail,
              managerId: managerId,
              memberPassword: password,
              windowWidth: $(window).width()
            });
            setCookie("email", values.email, { path: "/" });
            setCookie("pwd", hashedPassword, { path: "/" });
            setCookie("memberType", MemberType, { path: "/" });
            if (res.data.Member[0].MemberType === 0) {
              setMemberType(0);
            } else {
              setMemberType(1);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Hatalı Giriş! ",
            });
          }
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tüm Alanları Doldurunuz!!",
      });
    }
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      onSubmit();
    }
  };

  if (passwordReset) {
    return <PasswordReset />;
  }

  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard
              className="bg-light text-black my-5 mx-auto"
              style={{ borderRadius: "1rem", maxWidth: "400px" }}
            >
              <MDBCardBody className="p-3 d-flex flex-column mx-auto w-100">
                <center>
                  <img
                    src={Logo}
                    alt="şirket logo"
                    height={80}
                    width={150}
                  ></img>
                  <h5
                    sx={{
                      fontSize: { xs: "15px", sm: "20px" },
                      marginBottom: "20px",
                    }}
                  >
                    Oturum Aç
                  </h5>
                </center>
                <br></br>
                <div className="row justify-content-between text-left">
                  <div className="form-group col-sm-12 flex-column d-flex">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleChange("email")}
                        label="E-Mail"
                        onKeyPress={handleKeyPress}
                      />
                      <InputLabel>E-Mail</InputLabel>
                    </FormControl>
                  </div>
                </div>
                <div className="row justify-content-between text-left">
                  <div className="form-group col-sm-12 flex-column d-flex">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <InputLabel>Şifre</InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange("password")}
                        onKeyPress={handleKeyPress}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="password"
                      />
                      <br></br>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <p
                    className="small mb-3 pb-lg-2 float-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPasswordReset(true);
                    }}
                  >
                    Şifrenizi Mi Unuttunuz?
                  </p>
                </div>
                <br></br>
                <div className="row justify-content-end">
                  <div className="form-group col-sm-12">
                    <button
                      id="sub_btn"
                      className="btn btn-block"
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        textTransform: "capitalize",
                      }}
                      onClick={onSubmit}
                    >
                      Oturum Açın
                    </button>
                  </div>
                </div>
                <br></br>
                <p style={{ textAlign: "center", fontSize: "12px" }}>Copyright © 2023 <a href={process.env.REACT_APP_SADELABS_URL} target="_blank">SADELABS</a> - {process.env.REACT_APP_API_VERSION} </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
export default LoginPage;
