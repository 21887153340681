import React, { useState, useContext, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBSwitch
} from "mdb-react-ui-kit";
import AvatarPicker from '../components/AvatarPicker';
import AuthContext from "../context/AuthContext";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import bcrypt from "bcryptjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import LogContext from "../context/LogContext";
import Box from "@mui/material/Box";
import UploadImageContext from "../context/UploadImageContext";


export default function Profile() {
  const [auth, setAuth] = useContext(AuthContext);
  const [formValue, setFormValue] = useState({});
  const [value, setValue] = React.useState(null);
  const [newBirthday, setNewBirthday] = React.useState(null);
  var memberId = auth.memberId;
  const [checkedVisibility, setCheckedVisibility] = React.useState(false);
  const [updateProfileLog, setUpdateProfileLog] = useContext(LogContext);
  const [uploadImage, setUploadImage] = useContext(UploadImageContext);
  var imageName = uploadImage.imageName;
  var imageContent = uploadImage.imageContent;

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetMemberInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setFormValue({
            email: res.data.Member[0].MemberMail,
            username: res.data.Member[0].MemberName,
            phoneNumber: res.data.Member[0].PhoneNumber,
            firstpassword: res.data.Member[0].MemberPassword,
            address: res.data.Member[0].Address
          });
          setValue(
            moment(res.data.Member[0].BirthdayDate)
              .format("YYYY-MM-DD")
              .toString()
          );
        }
      });
  }, []);

  const { email, username, phoneNumber, firstpassword, address } = formValue;
  const [values, setValues] = React.useState({
    checked: false,
    showPassword: false,
    password: "",
    newPassword: "",
    newPasswordAgain: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setValues({
      ...values,
      checked: !checked,
      showPassword: !values.showPassword,
    });
  };
  const { checked } = values;
  const onchangeSwitch = (e) => {
    setCheckedVisibility(e.target.checked);
    setValues({
      password: "",
      newPassword: "",
      newPasswordAgain: "",
      checked: false,
      showPassword: false,
    });
  };

  const handleChangeForm = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    var fileData = new FormData();
    if (checkedVisibility === true) {
      if (
        username !== "" &&
        phoneNumber !== "" &&
        email !== "" &&
        address !== "" &&
        values.newPassword !== "" &&
        values.newPasswordAgain !== "" &&
        values.password !== "" &&
        value !== null
      ) {
        const passwordHashed = bcrypt.hashSync(
          values.password,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );

        if (firstpassword === passwordHashed) {
          if (values.newPassword !== values.newPasswordAgain) {
            Swal.fire({
              icon: "warning",
              title: "Girilen Şifreler Uyuşmamaktadır, Lütfen Tekrar Giriniz!!",
              showConfirmButton: false,
            });
          } else {
            const hashedPassword = bcrypt.hashSync(
              values.newPassword,
              "$2a$10$CwTycUXWue0Thq9StjUM0u"
            );
            fileData.append(imageName, imageContent);
            fileData.append("MemberMail", email);
            fileData.append("MemberName", username);
            fileData.append("PhoneNumber", phoneNumber);
            fileData.append("Address", address);
            fileData.append("BirthdayDate", newBirthday !== null
              ? newBirthday
              : moment.utc(value).local().format());
            fileData.append("MemberPassword", hashedPassword);
            fileData.append("MemberId", memberId);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/Member/UpdateProfile`,
                fileData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                if (res.data.Status === true) {
                  setUpdateProfileLog(true);
                  Swal.fire({
                    icon: "success",
                    title: "Profil Düzenleme İşlemi Başarılı",
                  }).then((result) => {
                    setFormValue({
                      email: email,
                      username: username,
                      phoneNumber: phoneNumber,
                      address: address,
                      firstpassword: hashedPassword,
                    });
                    setUploadImage({
                      imageName: "",
                      imageContent: ""
                    });
                  });
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: res.data.StatusCode,
                  }).then((result) => { });
                }
              });
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Girilen Mevcut Şifre Yanlıştır!!",
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    } else {
      if (
        username !== "" &&
        phoneNumber !== "" &&
        email !== "" &&
        address !== "" &&
        value !== null
      ) {
        fileData.append(imageName, imageContent);
        fileData.append("MemberMail", email);
        fileData.append("MemberName", username);
        fileData.append("PhoneNumber", phoneNumber);
        fileData.append("Address", address);
        fileData.append("BirthdayDate", newBirthday !== null
          ? newBirthday
          : moment.utc(value).local().format());
        fileData.append("MemberPassword", null);
        fileData.append("MemberId", memberId);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/Member/UpdateProfile`,
            fileData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data.Status === true) {
              setUpdateProfileLog(true)
              Swal.fire({
                icon: "success",
                title: "Profil Düzenleme İşlemi Başarılı",
              }).then((result) => {
                setFormValue({
                  email: email,
                  username: username,
                  phoneNumber: phoneNumber,
                  address: address,
                  firstpassword: null,
                });
                setUploadImage({
                  imageName: "",
                  imageContent: ""
                });
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: res.data.StatusCode,
              }).then((result) => { });
            }
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    }
  };
  return (
    <Box
      style={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],

        flexGrow: 1,
        height: "auto",
        overflow: "auto",
      }}
    >
      <MDBContainer className="py-5" style={{ marginTop: "60px" }}>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4" >
              <MDBCardBody className="text-center">
                <AvatarPicker></AvatarPicker>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <MDBCard className="mb-4">
              <MDBCardBody className="px-4 py-4 ">
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>
                      <b>Ad Soyad</b>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        id="username"
                        name="username"
                        type="text"
                        value={username}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>
                      <b>E-Mail</b>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        id="email"
                        name="email"
                        type="text"
                        value={email}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>
                      <b>Telefon</b>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <OutlinedInput
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        value={phoneNumber}
                        onChange={handleChange}
                        inputProps={{ maxLength: 11 }}
                      />
                    </FormControl>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>
                      <b>Doğum Tarihi</b>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id="date"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            setNewBirthday(
                              moment(newValue?.$d).format("YYYY-MM-DD")
                            );
                          }}
                          inputFormat="DD-MM-YYYY"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>
                      <b>İkametgah Adresi</b>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <TextField
                        id="address"
                        name="address"
                        multiline
                        maxRows={3}
                        value={address}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </MDBCol>
                </MDBRow>
                <br></br>
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  onChange={onchangeSwitch}
                  label="Şifre Güncelle"
                />
                <br></br>
                {checkedVisibility === true && (
                  <>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>
                          <b>Mevcut Şifre</b>
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                          <OutlinedInput
                            id="password"
                            name="password"
                            type={values.showPassword ? "text" : "password"}
                            onChange={handleChangeForm("password")}
                          />
                        </FormControl>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>
                          <b>Yeni Şifre</b>
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                          <OutlinedInput
                            id="newPassword"
                            name="newPassword"
                            type={values.showPassword ? "text" : "password"}
                            onChange={handleChangeForm("newPassword")}
                          />
                        </FormControl>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>
                          <b>Yeni Şifre Tekrar</b>
                        </MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                          <OutlinedInput
                            id="newPasswordAgain"
                            type={values.showPassword ? "text" : "password"}
                            onChange={handleChangeForm("newPasswordAgain")}
                          />
                        </FormControl>
                      </MDBCol>
                    </MDBRow>
                    <br></br>
                    <center>
                      <MDBSwitch
                        id="flexSwitchCheck"
                        checked={checked}
                        onChange={handleChange}
                        label="Şifreyi Göster"
                      />
                    </center>
                    <br></br>
                  </>
                )}
                <button
                  onClick={handleSubmit}
                  id="sub_btn"
                  className="btn btn-block"
                  style={{
                    backgroundColor: "grey",
                    color: "white",
                    textTransform: "capitalize",
                  }}
                >
                  Kaydet
                </button>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Box>
  );
}