import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    memberName: "",
    memberId: 0,
    managerId: 0,
    birthday: "",
    phone: "",
    memberType: 9,
    isManager: 9,
    memberMail: "",
    memberPassword: "",
    windowWidth: 0
  });

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
