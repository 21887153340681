import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import * as XLSX from "xlsx";
import Tooltip from "@mui/material/Tooltip";
import NavbarVisibilityContext from "../../context/NavbarVisibilityContext";
const now = dayjs(new Date());
var oneMonthAgo = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 1,
  new Date().getDate()
);

const ExportForDateRange = () => {
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState(oneMonthAgo);
  const [valueEnd, setValueEnd] = React.useState(now);
  const [visibleNavbar, setVisibleNavbar] = useContext(NavbarVisibilityContext);
  const handleClose = () => {
    setShow(false);
    setVisibleNavbar(true);
    setValue(oneMonthAgo);
    setValueEnd(now);
  };

  const handleShow = () => {
    setShow(true);
    setVisibleNavbar(false);
  };

  const handleSubmit = () => {
    if (value != null && valueEnd != null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/ExportToExcel`, {
          StartDate: moment.utc(value).local().format(),
          EndDate: valueEnd,
        })
        .then((res) => {
          if (res.data.Status === true) {
            const newData = res.data.LeaveLogList.map((row) => {
              return {
                ...row,
                StartDate: moment(row.StartDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                EndDate: moment(row.EndDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                CreateDate: moment(row.CreateDate)
                  .format("DD-MM-YYYY")
                  .toString(),
              };
              // delete row.tableData;

            });
            var colWidths = [20, 20, 10, 30, 20, 20, 20];
            const workSheet = XLSX.utils.json_to_sheet(newData);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(workSheet, [["İzne Çıkacağı Tarih", "İzinden Döneceği Tarih", "Toplam İzin Süresi (Gün)", "İzin İsteyen", "İzin Durumu", "Oluşturma Tarihi", "İzin Türü"]]);
            XLSX.utils.book_append_sheet(workBook, workSheet, "İzinTalepleri");
            let buf = XLSX.write(workBook, {
              bookType: "xlsx",
              type: "buffer",
            });
            workSheet["!cols"] = colWidths.map(function (width) {
              return { wch: width };
            });
            workBook.Sheets["Sheet1"] = workSheet;
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(
              workBook,
              `İzinTalepleri ${moment
                .utc(value)
                .local()
                .format("DD-MM-YYYY")} " " ${moment
                  .utc(valueEnd)
                  .local()
                  .format("DD-MM-YYYY")}.xlsx`
            );

            Swal.fire({
              icon: "success",
              title: "Excel'e Aktarma İşlemi Başarılı",
              hideClass: setShow(false),
            }).then((result) => {
              setValue(oneMonthAgo);
              setValueEnd(now);
              setVisibleNavbar(true);
            });
          } else if (res.data.StatusCode === "War101") {
            Swal.fire({
              icon: "warning",
              title: "Boş Kayıt!",
              text: "Girilen aralıkta kayıt bulunamadığı için indirme yapılamadı...",
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: res.data.StatusCode,
            });
          }
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tüm Alanları Doldurunuz!!",
        showConfirmButton: false,
      });
    }
  };

  return (
    <>
      <IconButton className="float-right mr-4" onClick={handleShow}>
        <Tooltip title="Excel'e Aktar">
          <DownloadIcon />
        </Tooltip>
      </IconButton>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header>
          <Modal.Title>
            <span>
              <ArticleOutlinedIcon />
              &nbsp; Excel'e Aktar
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Kayıt Oluşturma Tarih Aralığı</p>
          <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Başlangıç Tarihi *"
                value={value}
                defaultValue={dayjs(new Date())}
                onChange={(newValue) => {
                  setValue(moment(newValue?.$d).format("YYYY-MM-DD"));
                }}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="date"
                label="Bitiş Tarihi *"
                value={valueEnd}
                onChange={(newValue) => {
                  setValueEnd(moment(newValue?.$d).format("YYYY-MM-DD"));
                }}
                minDate={value}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-secondary" onClick={handleClose}>
            İptal
          </button>
          <button class="btn btn-primary" onClick={handleSubmit}>
            İndir
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ExportForDateRange;
