import React, { useContext } from "react";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import FlakyOutlinedIcon from "@mui/icons-material/FlakyOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AuthContext from "../context/AuthContext";

export default function SelectedListItem() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [auth] = useContext(AuthContext);
  const handleClick = () => {
    setOpen(!open);
  };
  var isManager = auth.isManager;
  var memberType = auth.memberType;
  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        height: "100%",
        marginTop: "10px",
        bgcolor: "background.paper",
      }}
    >
      <React.Fragment>
        {isManager === 1 && (
          <ListItemButton sx={{ mt: 4 }} onClick={handleClick}>
            <ListItemIcon>
              <InsertEmoticonIcon />
            </ListItemIcon>
            <ListItemText primary="İzin İşlemleri" />
            {/* {open ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
        )}
        {isManager === 1 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} selected={selectedIndex === 0}
                onClick={(event) => { handleListItemClick(event, 0); navigate("/LeaveList") }}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="İzin Listesi" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton selected={selectedIndex === 1} sx={{ pl: 4 }} onClick={(event) => { handleListItemClick(event, 1); navigate("/") }}>
                <ListItemIcon>
                  <FlakyOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Talep Listesi" />
              </ListItemButton>
            </List>
          </Collapse>
        )}
        {isManager === 0 && (
          <ListItemButton sx={{ mt: 4 }} selected={selectedIndex === 2} onClick={(event) => { handleListItemClick(event, 2); navigate("/") }}>
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="İzin Listesi" />
          </ListItemButton>
        )}
        {memberType === 1 && (
          <ListItemButton selected={selectedIndex === 3} onClick={(event) => { handleListItemClick(event, 3); navigate("/MemberTransactions") }}>
            <ListItemIcon>
              <GroupOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Kullanıcı İşlemleri" />
          </ListItemButton>
        )}
        <ListItemButton selected={selectedIndex === 4}
          onClick={(event) => { handleListItemClick(event, 4); navigate("/Profile") }}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Profil" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
            document.cookie = "pwd=;";
            document.cookie = "email=;";
            document.cookie = "memberType=;";
          }}
        >
          <ListItemIcon>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Çıkış Yap</ListItemText>
        </ListItemButton>
      </React.Fragment>
    </Box>
  );
}
