import React, { useContext, useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SelectedListItem from "../components/Navbar";
import Logo from "../assets/images/sadeteamlogo.png";
import { Routes, Route } from "react-router-dom";
import AddMember from "./AddMember";
import MemberTransactions from "./MemberTransactions";
import EditMember from "./EditMember";
import LeaveProcess from "../Leave/pages/LeaveProcess";
import AllLeaveProcess from "../Leave/pages/AllLeaveProcess";
import AuthContext from "../context/AuthContext";
import Profile from "./Profile";
import axios from "axios";
import LogContext from "../context/LogContext";
import NavbarVisibilityContext from "../context/NavbarVisibilityContext";
import Tooltip from "@mui/material/Tooltip";
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import Badge from '@mui/material/Badge';
import moment from "moment";


const AllRoutes = () => {
  const [auth] = useContext(AuthContext);
  var memberType = auth.memberType;
  var isManager = auth.isManager;
  if (memberType === 1) {
    return (
      <Routes>
        <Route path="/" element={<LeaveProcess />}></Route>
        <Route
          path="/MemberTransactions"
          element={<MemberTransactions />}
        ></Route>
        <Route path="/LeaveList" element={<AllLeaveProcess />}></Route>
        <Route path="/AddMember" element={<AddMember />}></Route>
        <Route path="/EditMember" element={<EditMember />}></Route>
        <Route path="/Profile" element={<Profile />}></Route>
      </Routes>
    );
  } else {
    if (isManager === 1) {
      return (
        <Routes>
          <Route path="/" element={<LeaveProcess />}></Route>
          <Route path="/LeaveList" element={<AllLeaveProcess />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<AllLeaveProcess />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>
        </Routes>
      );
    }
  }
};
const MemberPanel = () => {
  const drawerWidth = 230;
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      backgroundColor: "white",
      borderRight: "2px",
      borderTop: "2px grey solid",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  const mdTheme = createTheme();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [auth] = useContext(AuthContext);
  const [visibleNavbar, setVisibleNavbar] = useContext(NavbarVisibilityContext);
  const memberId = auth.memberId;
  const [memberName, setMemberName] = useState();
  const [totalLeaveTime, setTotalLeaveTime] = useState();
  const [remainingLeaveTime, setRemainingLeaveTime] = useState();
  const [startWorkDate, setStartWorkDate] = useState();
  const [totalLeaveLog, setTotalLeaveLog] = useContext(LogContext);
  const [updateProfileLog, setUpdateProfileLog] = useContext(LogContext)
  const [profileImage, setProfileImage] = useState()
  const [editMemberLog, setEditMemberLog] = useContext(LogContext)


  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetMemberInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setMemberName(res.data.Member[0].MemberName);
          setProfileImage(res.data.Member[0].ImagePath);
        }
      });
    setUpdateProfileLog(false);
  }, [updateProfileLog]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetMemberInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setMemberName(res.data.Member[0].MemberName);
          setStartWorkDate(res.data.Member[0].StartWorkDate);
          setRemainingLeaveTime(res.data.Member[0].RemainingLeaveTime);
        }
      });
    setEditMemberLog(false);
  }, [editMemberLog]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetMemberInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setTotalLeaveTime(res.data.Member[0].SumLeaveTime);
          setRemainingLeaveTime(res.data.Member[0].RemainingLeaveTime);
          setStartWorkDate(res.data.Member[0].StartWorkDate);
        }
      });
    setTotalLeaveLog(false);
  }, [totalLeaveLog]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {visibleNavbar && (
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px",
                backgroundColor: "gainsboro",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  marginLeft: "1px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
              >
                <img src={Logo} alt="şirket logo" height={80} width={150}></img>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Badge>
                <Typography
                  variant="h10"
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'none', sm: 'block', color: "black" } }}
                >
                  <p style={{ paddingTop: "7px", paddingRight: "30px" }}>
                    <b>İşe Giriş: </b><span>{startWorkDate != null ? moment(startWorkDate).format("DD-MM-YYYY").toString() : "-"}</span> <br />
                    <b>Kullanılan İzin: </b><span>{totalLeaveTime}</span><br />
                    <b>Kalan İzin: </b><span>{remainingLeaveTime}</span>
                  </p>
                </Typography>
                <IconButton sx={{ display: { md: 'flex' } }} size="large" color="inherit" onClick={() => { }}>
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none" }}
                  >
                    {profileImage !== null ? (
                      <Avatar alt="profile photo"
                        src={profileImage}
                      />
                    ) : <Avatar
                      src=""
                    />}

                  </Link>
                </IconButton>
              </Badge>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block', color: "black" } }}
              >
                <div>
                  {memberName} &nbsp;
                  {/* <Tooltip title="Toplam Kullanılan İzin Günü">
                    <span>{totalLeaveTime}</span>
                  </Tooltip> */}
                </div>
              </Typography>

            </Toolbar>
          </AppBar>
        )}
        {visibleNavbar && (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <List component="nav">
              <SelectedListItem />
            </List>
          </Drawer>
        )}
        <AllRoutes />
      </Box>
      <br></br>
      <br></br>
    </ThemeProvider>
  );
};

export default MemberPanel;
