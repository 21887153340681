import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import axios from "axios";
import bcrypt from "bcryptjs";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

export default function ForgotPassword() {
  const [values, setValues] = React.useState({
    checked: false,
    showPassword: false,
    newPassword: "",
    newPasswordAgain: "",
  });
  const handleChangeForm = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      checked: !checked,
      showPassword: !values.showPassword,
    });
  };
  const url = new URL(window.location.href);
  const handleSubmit = (event) => {
    if (values.newPassword !== "" && values.newPasswordAgain !== "") {
      if (values.newPassword !== values.newPasswordAgain) {
        Swal.fire({
          icon: "warning",
          title: "Girilen Şifreler Uyuşmamaktadır, Lütfen Tekrar Giriniz!!",
          showConfirmButton: false,
        });
      } else {
        const hashedPassword = bcrypt.hashSync(
          values.newPassword,
          "$2a$10$CwTycUXWue0Thq9StjUM0u"
        );
        axios
          .post(`${process.env.REACT_APP_API_URL}/Login/UpdatePassword`, {
            MemberMail: url.searchParams.get("email"),
            MemberPassword: hashedPassword,
          })
          .then((res) => {
            if (res.data.Status === true) {
              Swal.fire({
                icon: "success",
                title: "Şifre Yenileme İşlemi Başarılı",
              }).then((result) => {
                setTimeout(function () {
                  window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`);
                }, 2000);
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: res.data.StatusCode,
              });
            }
          });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tüm Alanları Doldurunuz!!",
        showConfirmButton: false,
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit();
    }
  };
  const { checked } = values;
  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-light text-black my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "400px" }}
          >
            <MDBCardBody className="p-3 d-flex flex-column mx-auto w-100">
              <center>
                <h5
                  sx={{
                    fontSize: { xs: "15px", sm: "20px" },
                    marginBottom: "20px",
                  }}
                >
                  Şifre Yenileme
                </h5>
              </center>
              <br></br>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel>Yeni Şifre</InputLabel>
                    <OutlinedInput
                      id="newPassword"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      label="Password"
                      name="newPassword"
                      onChange={handleChangeForm("newPassword")}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel>Yeni Şifre Tekrar</InputLabel>
                    <OutlinedInput
                      id="newPasswordAgain"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      label="checkpassword"
                      name="newPasswordAgain"
                      onChange={handleChangeForm("newPasswordAgain")}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row justify-content-between text-left">
                <div
                  id="check-box"
                  className="form-group col-sm-12 flex-column d-flex"
                >
                  <center>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox checked={checked} onChange={handleChange} />
                        }
                        label="Şifreyi Göster"
                      />
                    </FormControl>
                  </center>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="form-group col-sm-12">
                  <button
                    id="sub_btn"
                    className="btn btn-block"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
