import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import UploadImageContext from "../context/UploadImageContext"
import AuthContext from "../context/AuthContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));
function AvatarPicker() {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(null);
  const [auth, setAuth] = useContext(AuthContext);
  const memberId = auth.memberId;
  const [visibleRemoveButton, setVisibleRemoveButton] = useState(0);
  const [uploadImage, setUploadImage] = useContext(UploadImageContext)
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetProfileImage`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setCurrentImage(res.data.Member[0].ImagePath);
          res.data.Member[0].ImagePath !== null ?
            setVisibleRemoveButton(1)
            : setVisibleRemoveButton(0)

          if (res.data.Member[0].ImagePath === null) {
            setCurrentImage(null);
          }

        }
      });
  }, []);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentImage(URL.createObjectURL(file));
      setUploadImage({
        imageName: `${file.name}`,
        imageContent: file
      });
      setVisibleRemoveButton(1)
    }
    else {
      setCurrentImage(null)
      // setVisibleRemoveButton(0)
    }
  };

  const onClickFile = (event) => {
    const file = event.target.files[0];
    if (file === undefined) {
      setCurrentImage(null)
      setVisibleRemoveButton(0)

    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault()
    setCurrentImage(null)
    setUploadImage({
      imageName: null,
      imageContent: null
    });
    setVisibleRemoveButton(0)
    const file =
      document.querySelector('.file');
    file.value = '';
  };


  return (
    <form >
      <div className={classes.avatarContainer}>
        <Avatar
          alt="Profile Photo"
          src={currentImage}
          className={classes.avatar}
        />
      </div>
      <div>
        <input
          type="file"
          className="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ paddingTop: "30px" }}
          onClick={onClickFile}
        />
        {visibleRemoveButton === 1 && (
          <button
            onClick={(e) => { handleRemoveImage(e) }}
          >
            Kaldır
          </button>
        )
        }
      </div>

    </form >
  );
}
export default AvatarPicker;