import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Swal from "sweetalert2";
import AuthContext from "../../context/AuthContext";
import moment from "moment";
import LogContext from "../../context/LogContext";
import NavbarVisibilityContext from "../../context/NavbarVisibilityContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";
import UploadFile from "./UploadFile";
import UploadFileContext from "../context/UploadFileContext";

const NewDocumentRequest = () => {
  const [auth, setAuth] = useContext(AuthContext);
  const [leaveLogAdded, setLeaveLogAdded] = useContext(LogContext);
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState(null);
  const [valueEnd, setValueEnd] = React.useState(null);
  const [values, setValues] = React.useState({
    // totalLeaveTime: "",
    note: "",
  });
  // const [valueLeaveType, setValueLeaveType] = useState(null);
  const [valueManagerName, setvalueManagerName] = useState("");
  const [valueMemberName, setValueMemberName] = useState("");
  const [newValue, setNewValue] = React.useState(null);
  const [newValueEnd, setNewValueEnd] = React.useState(null);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [visibleNavbar, setVisibleNavbar] = useContext(NavbarVisibilityContext);
  const [valueManager, setValueManager] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [selectedManagerId, setSelectedManagerId] = React.useState(0);
  const [showFileUpload, setShowFileUpload] = React.useState(0);
  const [uploadFile, setUploadFile] = useContext(UploadFileContext);
  const [totalLeaveTime, setTotalLeaveTime] = useState();
  const [remainingLeaveTime, setRemainingLeaveTime] = useState();
  const [startWorkDate, setStartWorkDate] = useState();
  const [visibleInfoText, setVisibleInfoText] = useState();
  const [valueLeaveType, setValueLeaveType] = React.useState([]);
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = React.useState(0);
  const [selectedTotalLeaveTime, setSelectedTotalLeaveTime] = useState(0);
  const [totalLeaveLog, setTotalLeaveLog] = useContext(LogContext);
  var memberId = auth.memberId;
  var managerId = auth.managerId;
  var memberType = auth.memberType;
  var fileName = uploadFile.fileName;
  var fileContent = uploadFile.fileContent;
  var windowWidth = auth.windowWidth;

  const clearForm = () => {
    setValue(null);
    setValueEnd(null);
    // setValueLeaveType(null);
    setVisibleNavbar(true);
    setChecked(false);
    setShowFileUpload(0);
    setUploadFile({
      fileName: "",
      fileContent: "",
    });
    setSelectedLeaveTypeId(0);
    setSelectedManagerId(0);
    setSelectedTotalLeaveTime(0);
    setValues({ note: "" });
    setNewValueEnd(null);
    setNewValue(null);
  }
  const handleClose = () => {
    clearForm();
    setShow(false);
  };
  const onSelectedTotalLeaveTime = (e) => {
    setSelectedTotalLeaveTime(e.getAttribute("data-value"));
  }
  const onSelectedManager = (e) => {
    setSelectedManagerId(parseInt(e.getAttribute("data-value")));
  };

  const onSelectedLeaveType = (e) => {
    setSelectedLeaveTypeId(parseInt(e.getAttribute("data-value")));
  };
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/GetMemberInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setTotalLeaveTime(res.data.Member[0].SumLeaveTime);
          setRemainingLeaveTime(res.data.Member[0].RemainingLeaveTime);
          setStartWorkDate(res.data.Member[0].StartWorkDate);
          setValueMemberName(res.data.Member[0].MemberName);
        }
      });
    setTotalLeaveLog(false);
  }, [totalLeaveLog]);
  useEffect(() => {
    windowWidth < 600 ? setVisibleInfoText(true) : setVisibleInfoText(false);
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetLeaveType`, {
        responseType: "json",
      })
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setValueLeaveType(res.data.LeaveType);
        }
      });
  }, []);
  var LeaveStatusId = memberType === 0 ? 2 : 3;
  const handleShow = () => {
    Swal.fire({
      html: ` <b>İşe Giriş: </b>${startWorkDate != null ? moment(startWorkDate).format("DD-MM-YYYY").toString() : "-"}<br/>
      <b>Kullanılan İzin: </b>${totalLeaveTime}<br/>
      <b>Kalan İzin: </b>${remainingLeaveTime}`,
      // icon: 'info',
      showCloseButton: true,
      // showCancelButton: true,
      showConfirmButton: false,
    })
    setButtonDisabled(false);
    axios
      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetManagerInfo`, {
        MemberId: memberId,
      })
      .then((res) => {
        if (managerId !== null) {
          if (res.data.Status === true) {
            setValueManager(res.data.Member);
            // setvalueManagerName(res.data.ManagerName);
            setValueMemberName(valueMemberName);
            setShow(true);
            setVisibleNavbar(false);
          }
        } else {
          setvalueManagerName(valueMemberName);
          // setValueMemberName(memberName);
          setShow(true);
          setVisibleNavbar(false);
          setSelectedManagerId(null);
        }
      });
  };

  // const leaveType = [
  //   {
  //     value: "1",
  //     label: "Ücretsiz İzin",
  //   },
  //   {
  //     value: "2",
  //     label: "Ücretli İzin /Yıllık",
  //   },

  //   {
  //     value: "3",
  //     label: "Raporlu",
  //   },
  //   {
  //     value: "4",
  //     label: "Görevli",
  //   },
  // ];
  const LeaveTimes = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "1.5",
      label: "1.5",
    },

    {
      value: "2",
      label: "2",
    },
    {
      value: "2.5",
      label: "2.5",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "3.5",
      label: "3.5",
    },
    {
      value: "4",
      label: "4",
    },

    {
      value: "4.5",
      label: "4.5",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "5.5",
      label: "5.5",
    },
    {
      value: "6",
      label: "6",
    },

    {
      value: "6.5",
      label: "6.5",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "7.5",
      label: "7.5",
    },
    {
      value: "8",
      label: "8",
    },

    {
      value: "8.5",
      label: "8.5",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "9.5",
      label: "9.5",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "10.5",
      label: "10.5",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "11.5",
      label: "11.5",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "12.5",
      label: "12.5",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "13.5",
      label: "13.5",
    },
    {
      value: "14",
      label: "14",
    },

    {
      value: "14.5",
      label: "14.5",
    },
    {
      value: "15",
      label: "15",
    }
  ]
  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const onchangeSwitch = (e) => {
    setChecked(e.target.checked);
    setValues({
      ...values,
      note: "",
    });
  };
  var reg = new RegExp(/^[0-9]+$/);
  const handleSubmit = () => {
    var fileData = new FormData();
    if (checked === true) {
      //note eklenecekse
      if (
        selectedTotalLeaveTime !== 0 &&
        // values.totalLeaveTime !== "" &&
        newValue != null &&
        newValueEnd != null &&
        // valueLeaveType != null &&
        selectedLeaveTypeId !== 0 &&
        values.note !== "" &&
        selectedManagerId !== 0
      ) {
        // if (!reg.test(values.totalLeaveTime, reg)) {
        //   //geçersiz izin süresi
        //   Swal.fire({
        //     icon: "warning",
        //     title: "Girilen İzin Süresi Geçersiz",
        //     showConfirmButton: false,
        //   });
        // } else {
        //geçerli izin süresi
        if (selectedLeaveTypeId === 3 && fileContent !== "") {
          setButtonDisabled(true);
          // console.log("1");
          fileData.append(fileName, fileContent);
          fileData.append("TotalLeaveTime", selectedTotalLeaveTime);
          fileData.append("StartDate", newValue);
          fileData.append("EndDate", newValueEnd);
          fileData.append("LeaveTypeId", selectedLeaveTypeId);
          fileData.append("MemberId", memberId);
          fileData.append("LeaveStatusId", LeaveStatusId);
          fileData.append("ManagerId", selectedManagerId);
          fileData.append("Note", values.note);
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
              fileData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              if (res.data.Status === true) {
                setLeaveLogAdded(true);
                if (managerId === null) {
                  setTotalLeaveLog(true);
                }
                Swal.fire({
                  icon: "success",
                  title: "Yeni İzin Talebi Başarılı",
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: res.data.StatusCode,
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              }
            });
        }
        if (selectedLeaveTypeId === 3 && fileContent === "") {
          //rapor eklemeden kayıt edecek mi diye sor (hayır sa rapor eklicek)
          //eğer cevap hayır ise =rapor eklicek
          Swal.fire({
            icon: "warning",
            title: "Rapor Yüklemeden Kayıt Etmek İstiyor Musunuz? ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: "Evet",
            denyButtonText: "Hayır",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          }).then((result) => {
            if (!result.isConfirmed) {
              //cevap hayır=raporlu yüklemek isterse
              if (fileContent === "") {
                Swal.fire({
                  icon: "warning",
                  title:
                    "Rapor Yükleyebilmek İçin Dosya Seçip Yükle Butonuna Basınız ",
                });
              } else {
                //file seçilmişse
                setButtonDisabled(true);
                // console.log("2");
                fileData.append(fileName, fileContent);
                fileData.append(
                  "TotalLeaveTime",
                  selectedTotalLeaveTime
                );
                fileData.append("StartDate", newValue);
                fileData.append("EndDate", newValueEnd);
                fileData.append("LeaveTypeId", selectedLeaveTypeId);
                fileData.append("MemberId", memberId);
                fileData.append("LeaveStatusId", LeaveStatusId);
                fileData.append("ManagerId", selectedManagerId);
                fileData.append("Note", values.note);
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
                    fileData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.Status === true) {
                      setLeaveLogAdded(true);
                      if (managerId === null) {
                        setTotalLeaveLog(true);
                      }
                      Swal.fire({
                        icon: "success",
                        title: "Yeni İzin Talebi Başarılı",
                        hideClass: setShow(false),
                      }).then((result) => {
                        clearForm();
                      });
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: res.data.StatusCode,
                        hideClass: setShow(false),
                      }).then((result) => {
                        clearForm();
                      });
                    }
                  });
              }
            } else {
              //raporsuz yüklemek isterse
              setButtonDisabled(true);
              // console.log("3");
              fileData.append(fileName, fileContent);
              fileData.append(
                "TotalLeaveTime",
                selectedTotalLeaveTime
              );
              fileData.append("StartDate", newValue);
              fileData.append("EndDate", newValueEnd);
              fileData.append("LeaveTypeId", selectedLeaveTypeId);
              fileData.append("MemberId", memberId);
              fileData.append("LeaveStatusId", LeaveStatusId);
              fileData.append("ManagerId", selectedManagerId);
              fileData.append("Note", values.note);
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
                  fileData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )

                .then((res) => {
                  if (res.data.Status === true) {
                    setLeaveLogAdded(true);
                    if (managerId === null) {
                      setTotalLeaveLog(true);
                    }
                    Swal.fire({
                      icon: "success",
                      title: "Yeni İzin Talebi Başarılı",
                      hideClass: setShow(false),
                    }).then((result) => {
                      clearForm();
                    });
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: res.data.StatusCode,
                      hideClass: setShow(false),
                    }).then((result) => {
                      clearForm();
                    });
                  }
                });
            }
          });
        }
        if (selectedLeaveTypeId !== 3) {
          //izin türü raporlu değilse
          setButtonDisabled(true);
          // console.log("4");
          fileData.append(fileName, fileContent);
          fileData.append("TotalLeaveTime", selectedTotalLeaveTime);
          fileData.append("StartDate", newValue);
          fileData.append("EndDate", newValueEnd);
          fileData.append("LeaveTypeId", selectedLeaveTypeId);
          fileData.append("MemberId", memberId);
          fileData.append("LeaveStatusId", LeaveStatusId);
          fileData.append("ManagerId", selectedManagerId);
          fileData.append("Note", values.note);
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
              fileData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((res) => {
              if (res.data.Status === true) {
                setLeaveLogAdded(true);
                if (managerId === null) {
                  setTotalLeaveLog(true);
                }
                Swal.fire({
                  icon: "success",
                  title: "Yeni İzin Talebi Başarılı",
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: res.data.StatusCode,
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              }
            });
        }
        // }
      } else {
        //boş alan varsa
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    } else {
      //note eklenmiyecekse
      if (
        // values.totalLeaveTime !== "" &&
        selectedTotalLeaveTime !== 0 &&
        newValue != null &&
        newValueEnd != null &&
        selectedLeaveTypeId !== 0 &&
        selectedManagerId !== 0
      ) {
        // if (!reg.test(values.totalLeaveTime, reg)) {
        //   //geçersiz izin süresi
        //   Swal.fire({
        //     icon: "warning",
        //     title: "Girilen İzin Süresi Geçersiz",
        //     showConfirmButton: false,
        //   });
        // } else {
        //geçerli izin süresi
        if (selectedLeaveTypeId === 3 && fileContent !== "") {
          setButtonDisabled(true);
          // console.log("5");
          fileData.append(fileName, fileContent);
          fileData.append("TotalLeaveTime", selectedTotalLeaveTime);
          fileData.append("StartDate", newValue);
          fileData.append("EndDate", newValueEnd);
          fileData.append("LeaveTypeId", selectedLeaveTypeId);
          fileData.append("MemberId", memberId);
          fileData.append("LeaveStatusId", LeaveStatusId);
          fileData.append("ManagerId", selectedManagerId);
          fileData.append("Note", null);
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
              fileData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((res) => {
              if (res.data.Status === true) {
                setLeaveLogAdded(true);
                if (managerId === null) {
                  setTotalLeaveLog(true);
                }
                Swal.fire({
                  icon: "success",
                  title: "Yeni İzin Talebi Başarılı",
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: res.data.StatusCode,
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              }
            });
        }
        if (selectedLeaveTypeId === 3 && fileContent === "") {
          //rapor eklemeden kayıt edecek mi diye sor (hayır sa rapor eklicek)
          //eğer cevap hayır ise =rapor eklicek

          Swal.fire({
            icon: "warning",
            title: "Rapor Yüklemeden Kayıt Etmek İstiyor Musunuz? ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: "Evet",
            denyButtonText: "Hayır",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          }).then((result) => {
            if (!result.isConfirmed) {
              //cevap hayır=raporlu yüklemek isterse
              if (fileContent === "") {
                Swal.fire({
                  icon: "warning",
                  title:
                    "Rapor Yükleyebilmek İçin Dosya Seçip Yükle Butonuna Basınız ",
                });
              } else {
                //file seçilmişse
                setButtonDisabled(true);
                // console.log("6");
                fileData.append(fileName, fileContent);
                fileData.append(
                  "TotalLeaveTime",
                  selectedTotalLeaveTime
                );
                fileData.append("StartDate", newValue);
                fileData.append("EndDate", newValueEnd);
                fileData.append("LeaveTypeId", selectedLeaveTypeId);
                fileData.append("MemberId", memberId);
                fileData.append("LeaveStatusId", LeaveStatusId);
                fileData.append("ManagerId", selectedManagerId);
                fileData.append("Note", null);
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
                    fileData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.Status === true) {
                      setLeaveLogAdded(true);
                      if (managerId === null) {
                        setTotalLeaveLog(true);
                      }
                      Swal.fire({
                        icon: "success",
                        title: "Yeni İzin Talebi Başarılı",
                        hideClass: setShow(false),
                      }).then((result) => {
                        clearForm();
                      });
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: res.data.StatusCode,
                        hideClass: setShow(false),
                      }).then((result) => {
                        clearForm();
                      });
                    }
                  });
              }
            } else {
              //raporsuz yüklemek isterse
              setButtonDisabled(true);
              // console.log("7");
              fileData.append(fileName, fileContent);
              fileData.append(
                "TotalLeaveTime",
                selectedTotalLeaveTime
              );
              fileData.append("StartDate", newValue);
              fileData.append("EndDate", newValueEnd);
              fileData.append("LeaveTypeId", selectedLeaveTypeId);
              fileData.append("MemberId", memberId);
              fileData.append("LeaveStatusId", LeaveStatusId);
              fileData.append("ManagerId", selectedManagerId);
              fileData.append("Note", null);
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
                  fileData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  if (res.data.Status === true) {
                    setLeaveLogAdded(true);
                    if (managerId === null) {
                      setTotalLeaveLog(true);
                    }
                    Swal.fire({
                      icon: "success",
                      title: "Yeni İzin Talebi Başarılı",
                      hideClass: setShow(false),
                    }).then((result) => {
                      clearForm();
                    });
                  } else {
                    Swal.fire({
                      icon: "warning",
                      title: res.data.StatusCode,
                      hideClass: setShow(false),
                    }).then((result) => {
                      clearForm();
                    });
                  }
                });
            }
          });
        }
        if (selectedLeaveTypeId !== 3) {
          //izin türü raporlu değilse
          setButtonDisabled(true);
          // console.log("8");
          fileData.append(fileName, fileContent);
          fileData.append("TotalLeaveTime", selectedTotalLeaveTime);
          fileData.append("StartDate", newValue);
          fileData.append("EndDate", newValueEnd);
          fileData.append("LeaveTypeId", selectedLeaveTypeId);
          fileData.append("MemberId", memberId);
          fileData.append("LeaveStatusId", LeaveStatusId);
          fileData.append("ManagerId", selectedManagerId);
          fileData.append("Note", null);
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/LeaveRequest/NewDocumentRequest`,
              fileData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              if (res.data.Status === true) {
                setLeaveLogAdded(true);
                if (managerId === null) {
                  setTotalLeaveLog(true);
                }
                Swal.fire({
                  icon: "success",
                  title: "Yeni İzin Talebi Başarılı",
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: res.data.StatusCode,
                  hideClass: setShow(false),
                }).then((result) => {
                  clearForm();
                });
              }
            });
        }
        // }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Tüm Alanları Doldurunuz!!",
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <>
      {visibleInfoText ? (
        <>
          <h6 style={{ fontSize: '12px', marginLeft: "10px", marginTop: "8px", textAlign: "right" }}>İzin Bilgilerini Görebilmek İçin <b>Yeni Belge</b> Butonuna Tıklayınız.</h6>
          <Button className="ml-3" variant="outlined" onClick={handleShow}>
            <span>
              <i className="fa-solid fa-add"></i>&nbsp; Yeni Belge
            </span>
          </Button>
        </>
      ) : (
        <>
          <Button className="ml-3" variant="outlined" onClick={handleShow}>
            <span>
              <i className="fa-solid fa-add"></i>&nbsp; Yeni Belge
            </span>
          </Button>
        </>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header>
          <Modal.Title>
            <span>
              <ArticleOutlinedIcon />
              &nbsp; Yeni Belge
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
            <TextField
              type="text"
              id="userName"
              label="İzin İsteyen * "
              name="userName"
              disabled={true}
              value={valueMemberName}
              onChange={(e) => setValueMemberName(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <TextField
              id="leaveType"
              select
              label="İzin Türü *"
              name="leaveType"
              // value={valueLeaveType}
              onChange={(e) => {
                // setValueLeaveType(e.target.value);
                if (e.target.value === 3) {
                  setShowFileUpload(1);
                } else {
                  setShowFileUpload(0);
                }
              }}
            >
              {/* {leaveType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem> */}
              {valueLeaveType.map((option) => (
                option.LeaveTypeId !== 1 &&
                <MenuItem
                  key={option.LeaveTypeId}
                  value={option.LeaveTypeId}
                  onClick={(e) => onSelectedLeaveType(e.target)}
                >
                  {option.LeaveTypeName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {showFileUpload === 1 && (
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <UploadFile />
            </FormControl>
          )}
          <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="İzne Çıkacağı Tarih *"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  setNewValue(moment(newValue?.$d).format("YYYY-MM-DD"));
                }}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl variant="outlined" fullWidth sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="date"
                label="İzinden Döneceği Tarih *"
                value={valueEnd}
                onChange={(newValueEnd) => {
                  setValueEnd(newValueEnd);
                  setNewValueEnd(moment(newValueEnd?.$d).format("YYYY-MM-DD"));
                }}
                minDate={value}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            {/* <TextField
              type="number"
              inputProps={{ min: 1 }}
              id="totalLeaveTime"
              label="Toplam İzin Süresi (Gün) * "
              name="totalLeaveTime"
              onChange={handleChange("totalLeaveTime")}
            /> */}
            <TextField
              id="totalLeaveTime"
              select
              label="Toplam İzin Süresi (Gün) *"
              name="totalLeaveTime"
              // value={valueLeaveType}
              // onChange={handleChange("totalLeaveTime")}
              SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }} // add this line
            >
              {LeaveTimes.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={(e) => onSelectedTotalLeaveTime(e.target)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            {managerId !== null && (
              <TextField id="manager" select label="Onaya Gönder *">
                {valueManager.map((option) => (
                  <MenuItem
                    key={option.MemberId}
                    value={option.MemberId}
                    onClick={(e) => onSelectedManager(e.target)}
                  >
                    {option.MemberName}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {managerId === null && (
              <TextField
                id="manager"
                label="Onaya Gönder *"
                disabled={true}
                value={valueManagerName}
                onChange={(e) => setvalueManagerName(e.target.value)}
              ></TextField>
            )}
          </FormControl>
          <FormControlLabel
            control={<Switch checked={checked} onChange={onchangeSwitch} />}
            label="Not Ekle"
          />
          {checked === true && (
            <FormControl fullWidth sx={{ m: 1 }}>
              <OutlinedInput
                type="text"
                id="note"
                name="note"
                value={values.note}
                onChange={handleChange("note")}
              />
              <br></br>
            </FormControl>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Kapat
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={buttonDisabled}
          >
            Kaydet
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NewDocumentRequest;
