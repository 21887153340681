import { createContext, useState } from "react";

const UploadFileContext = createContext({});

export const UploadFileProvider = ({ children }) => {
    const [uploadFile, setUploadFile] = useState({
        fileName: "",
        fileContent: ""
    });
    return (
        <UploadFileContext.Provider value={[uploadFile, setUploadFile]}>
            {children}
        </UploadFileContext.Provider>
    );
};

export default UploadFileContext;