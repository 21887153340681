import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { LogProvider } from "./context/LogContext";
import { CookiesProvider } from "react-cookie";
import { NavbarProvider } from "./context/NavbarVisibilityContext";
import { UploadFileProvider } from "./Leave/context/UploadFileContext";
import { UploadImageProvider } from "./context/UploadImageContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <AuthProvider>
        <LogProvider>
          <NavbarProvider>
            <UploadFileProvider>
              <UploadImageProvider>
                <App />
              </UploadImageProvider>
            </UploadFileProvider>
          </NavbarProvider>
        </LogProvider>
      </AuthProvider>
    </CookiesProvider>
  </BrowserRouter>
);
