import { createContext, useState } from "react";

const LogContext = createContext({});

export const LogProvider = ({ children }) => {
  const [leaveLogAdded, setLeaveLogAdded] = useState(false);
  const [updateProfileLog, setUpdateProfileLog] = useState(false);
  const [totalLeaveLog, setTotalLeaveLog] = useState(false);
  const [visibleForgotPassword, setVisibleForgotPassword] = useState(0);
  const [editMemberLog, setEditMemberLog] = useState(false);

  return (
    <LogContext.Provider
      value={[
        leaveLogAdded,
        setLeaveLogAdded,
        totalLeaveLog,
        setTotalLeaveLog,
        visibleForgotPassword,
        setVisibleForgotPassword,
        updateProfileLog, setUpdateProfileLog,
        editMemberLog, setEditMemberLog
      ]}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogContext;
