import { createContext, useState } from "react";

const NavbarVisibilityContext = createContext({});

export const NavbarProvider = ({ children }) => {
  const [visibleNavbar, setVisibleNavbar] = useState(true);

  return (
    <NavbarVisibilityContext.Provider value={[visibleNavbar, setVisibleNavbar]}>
      {children}
    </NavbarVisibilityContext.Provider>
  );
};

export default NavbarVisibilityContext;
