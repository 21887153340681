import { useState, useContext } from "react";
import UploadFileContext from "../context/UploadFileContext";

function UploadFile() {
  const [file, setFile] = useState();
  const [upload, setUpload] = useState(0);
  const [uploadFile, setUploadFile] = useContext(UploadFileContext);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files) {
      // setUpload(0);
      setButtonDisabled(false)
      setFile(e.target.files[0]);
      if (!file) {
        setUpload(1);
      }
    }
  };


  const handleUploadClick = () => {
    setButtonDisabled(false);
    if (!file) {
      return;
    }
    setUploadFile({
      fileName: `${file.name}`,
      fileContent: file
    });
    setButtonDisabled(true);
  };

  return (
    <div>
      <div>
        <input
          type="file"
          style={{ float: "left" }}
          onChange={handleFileChange}
        />
        <div>{file && ` `}</div>
      </div>
      <div>
        {upload === 1 && (
          <button
            disabled={buttonDisabled}
            style={{
              margin: "0px",
              paddingRight: "3px",
              paddingTop: "1px",
              paddingBottom: "1px",
              paddingLeft: "3px",
              border: "1px solid black",
              borderRadius: " 2px",
            }}
            onClick={handleUploadClick}
          >
            Yükle
          </button>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
