import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AuthContext from "../../context/AuthContext";
import LogContext from "../../context/LogContext";
import DownloadIcon from "@mui/icons-material/Download";
import ExportForDateRange from "../components/ExportForDateRange";
import NewDocumentRequest from "../components/NewDocumentRequest";
import moment from "moment";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ReactFloatingBalloons } from "react-floating-balloons";
import { makeStyles } from "@material-ui/core/styles";
// import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

export default function AllLeaveList() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveLogAdded, setLeaveLogAdded] = useContext(LogContext);
  const [totalLeaveLog, setTotalLeaveLog] = useContext(LogContext);
  const [auth] = useContext(AuthContext);
  const [sortConfig, setSortConfig] = useState({
    key: "CreateDate",
    direction: "desc",
  });
  const [uploading, setUploading] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  var memberId = auth.memberId;
  var isManager = auth.isManager;

  const AllLeaveList = () => {
    setUploading(true);
    setTotalLeaveLog(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/IsBirthdayDate`, {
        MemberId: memberId,
      })
      .then(function (res) {
        if (res.data.Status === true) {
          if (res.data.Value === 1) {
            setShowAnimation(true);
          } else {
            setShowAnimation(false);
          }
        } else {
          setShowAnimation(false);
        }
      });
    axios
      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetAllLeaveList`, {
        MemberId: memberId,
      })
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setUploading(false);
          setRows(
            res.data.LeaveLogList.map((leavelog) => {
              return {
                ...leavelog,
                CreateDate: moment(leavelog.CreateDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                StartDate: moment(leavelog.StartDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                EndDate: moment(leavelog.EndDate)
                  .format("DD-MM-YYYY")
                  .toString(),
              };
            })
          );
        }
      });
  };

  useEffect(() => {
    if (leaveLogAdded === false) {
      return;
    }
    AllLeaveList();
    setLeaveLogAdded(false);
  }, [leaveLogAdded]);

  useEffect(() => {
    AllLeaveList();
  }, []);
  const useStyles = makeStyles((theme) => ({
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }));
  const classes = useStyles();
  const handleSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      if (key === "MemberName") {
        return a.MemberName.localeCompare(b.MemberName, "tr", {
          sensitivity: "base",
        });
      }
      if (key === "LeaveType") {
        return a.LeaveType.localeCompare(b.LeaveType, "tr", {
          sensitivity: "base",
        });
      }
      // if (key === "ManagerName") {
      //   return a.ManagerName.localeCompare(b.ManagerName, "tr", {
      //     sensitivity: "base",
      //   });
      // }
      if (key === "CreateDate" || key === "StartDate" || key === "EndDate") {
        const dateA = new Date(a[key].split("-").reverse().join("-"));
        const dateB = new Date(b[key].split("-").reverse().join("-"));
        return dateA - dateB;
      }
      if (key === "TotalLeaveTime") {
        return a.TotalLeaveTime - b.TotalLeaveTime;
      }
      return 0;
    });

    if (direction === "desc") {
      sorted.reverse();
    }
    setRows(sorted);
  };
  const getArrowIcon = (key) => {
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      return <ArrowDropUpIcon sx={{ color: "green !important" }} />;
    }
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      return <ArrowDropDownIcon sx={{ color: "green !important" }} />;
    }
    return <span />;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredData = rows.filter((row) => {
    return (
      row.MemberName.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.LeaveType.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.StartDate.includes(searchQuery) ||
      row.EndDate.includes(searchQuery) ||
      row.CreateDate.includes(searchQuery) ||
      row.TotalLeaveTime.toString().includes(searchQuery) ||
      (row.LeaveStatus === "Onaylandı" &&
        row.ManagerName.toLocaleLowerCase("tr-TR").includes(
          searchQuery.toLocaleLowerCase("tr-TR")
        ))
    );
  });

  const handleChangeSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  return (
    <>
      {uploading ? (
        <Box
          sx={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate('-50%', '-50%')",
          }}
        >
          <CircularProgress />
        </Box>
      ) : showAnimation === true && isManager === 0 ? (
        <ReactFloatingBalloons
          count={4}
          msgText="Happy Birthday !!"
          colors={["yellow", "purple", "red", "blue"]}
          popVolumeLevel={0}
        />
      ) : null}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <p align="left" style={{ fontSize: 25, padding: 20 }}>
          Tüm İzin Talepleri
        </p>
        <br></br>
        <NewDocumentRequest />
        <ExportForDateRange />
        <br></br>
        <p
          align="right"
          style={{ fontSize: 13, paddingTop: 60, paddingRight: 20 }}
        >
          <b>Toplam Talep Sayısı: </b> {rows.length}
        </p>
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          label="Search"
          value={searchQuery}
          placeholder="Liste İçinde Ara"
          onChange={handleChangeSearch}
          variant="outlined"
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("CreateDate")}
                >
                  <b>Oluşturma Tarihi</b>
                  {getArrowIcon("CreateDate")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("MemberName")}
                >
                  <b>İzin İsteyen</b>
                  {getArrowIcon("MemberName")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("LeaveType")}
                >
                  <b>İzin Türü</b>
                  {getArrowIcon("LeaveType")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("StartDate")}
                >
                  <b>İzne Çıkacağı Tarih</b>
                  {getArrowIcon("StartDate")}
                </TableCell>
                <TableCell align="center" onClick={() => handleSort("EndDate")}>
                  <b>İzinden Döneceği Tarih</b>
                  {getArrowIcon("EndDate")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("TotalLeaveTime")}
                >
                  <b>Toplam İzin Süresi (Gün)</b>
                  {getArrowIcon("TotalLeaveTime")}
                </TableCell>
                <TableCell align="center">
                  <b>Rapor</b>
                </TableCell>
                <TableCell
                  align="center"
                // onClick={() => handleSort("ManagerName")}
                >
                  <b>Onay Veren</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const color =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? "yellow"
                      : row.LeaveStatus === "Onaylandı"
                        ? "green"
                        : "red";
                  const title =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? "Onay Bekliyor"
                      : row.LeaveStatus === "Onaylandı"
                        ? "Onaylandı"
                        : "Reddedildi";
                  const downloadIconDisplay =
                    row.FileName !== null &&
                      row.FilePath !== null &&
                      row.IsReportVisible === 1
                      ? 1
                      : 0;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Tooltip title={title}>
                          <button
                            className="square"
                            style={{
                              backgroundColor: color,
                              width: 15,
                              height: 15,
                              borderRadius: "40%",
                            }}
                          ></button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {row.ImagePath === null ? (
                          <Avatar
                            alt="Profile Photo"
                            // src={`https://avatars.dicebear.com/api/initials/${row.MemberName}.svg`}
                            {...stringAvatar(`${row.MemberName}`)}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        ) :
                          <Avatar
                            alt="Profile Photo"
                            src={row.ImagePath}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        }
                      </TableCell>

                      <TableCell align="center">{row.CreateDate}</TableCell>
                      <TableCell align="center">{row.MemberName}</TableCell>
                      <TableCell align="center">{row.LeaveType}</TableCell>
                      <TableCell align="center">{row.StartDate}</TableCell>
                      <TableCell align="center">{row.EndDate}</TableCell>
                      <TableCell align="center">{row.TotalLeaveTime}</TableCell>

                      <TableCell align="center">
                        {downloadIconDisplay === 1 && (
                          <Tooltip title="Raporu İndir">
                            <Link
                              style={{ color: "gray" }}
                              to={
                                `${process.env.REACT_APP_SADETEAM_URL}` +
                                row.FilePath
                              }
                              //to={"C:/ceren/sade-iot-team" + (row.FilePath)}
                              download={row.FileName}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon />
                            </Link>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.LeaveStatus === "Onaylandı"
                          ? row.ManagerName === ""
                            ? row.MemberName
                            : row.Rejection_ApprovalManagerName
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </>
  );
}
