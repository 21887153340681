import "./App.css";
import { useState, useContext } from "react";
import LoginPage from "./pages/LoginPage";
import MemberPanel from "./pages/MemberPanel";
import { useCookies } from "react-cookie";
import LogContext from "./context/LogContext";
import ForgotPassword from "./pages/ForgotPassword";
function App() {
  const [cookie] = useCookies(["email", "pwd", "memberType"]);
  const [memberType, setMemberType] = useState(cookie.memberType);
  const [visibleForgotPassword, setVisibleForgotPassword] = useContext(LogContext);
  if (memberType === 1 || memberType === 0) {
    return <MemberPanel setMemberType={setMemberType} />;
  } if (visibleForgotPassword === 1) {
    return <ForgotPassword />
  }
  else {
    return <LoginPage setMemberType={setMemberType} />;
  }
}
export default App;
