import { createContext, useState } from "react";

const UploadImageContext = createContext({});

export const UploadImageProvider = ({ children }) => {
    const [uploadImage, setUploadImage] = useState({
        imageName: "",
        imageContent: ""
    });
    return (
        <UploadImageContext.Provider value={[uploadImage, setUploadImage]}>
            {children}
        </UploadImageContext.Provider>
    );
};

export default UploadImageContext;