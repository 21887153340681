import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import axios from "axios";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";


export default function PasswordReset() {
  const [values, setValues] = React.useState({
    email: ""
  });

  const handleChangeForm = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSubmit = (event) => {
    if (values.email !== "") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/Login/FindEmail`, {
          MemberMail: values.email,
          LoginUrl: `${process.env.REACT_APP_LOGIN_URL}`
        })
        .then((res) => {
          if (res.data.Value === 1 && res.data.Status === true) {
            Swal.fire({
              icon: "success",
              title: "Şifre Sıfırlama Linki E-Mailinize Başarılıyla Gönderildi",
            })
              .then((result) => {
                document.cookie = "pwd=;";
                document.cookie = "email=;";
                document.cookie = "memberType=;";
                window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
              });
          } else if (res.data.Value === 0) {
            Swal.fire({
              icon: "warning",
              title: "Geçersiz mail adresi girdiniz!",
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: res.data.StatusCode,
            });
          }
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tüm Alanları Doldurunuz!!",
        showConfirmButton: false,
      });

    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleSubmit();
    }
  };

  const goBack = () => {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-light text-black my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "400px" }}
          >
            <MDBCardBody className="p-3 d-flex flex-column mx-auto w-100">
              <div style={{ width: "100%" }}>
                <IconButton className="float-right" onClick={goBack}>
                  <KeyboardBackspaceIcon />
                </IconButton>
              </div>
              <center>
                <h5
                  sx={{
                    fontSize: { xs: "15px", sm: "20px" },
                    marginBottom: "20px",
                  }}
                >
                  Şifre Sıfırlama
                </h5>
              </center>
              <br></br>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      id="email"
                      label="E-Mail"
                      name="email"
                      onChange={handleChangeForm("email")}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="form-group col-sm-12">
                  <button
                    id="sub_btn"
                    className="btn btn-block"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSubmit}
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
