import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AuthContext from "../../context/AuthContext";
import LogContext from "../../context/LogContext";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import { ReactFloatingBalloons } from "react-floating-balloons";
import { makeStyles } from "@material-ui/core/styles";
// import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

export default function LeaveList() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [changeColor, setChangeColor] = useState(0);
  const [auth] = useContext(AuthContext);
  const [totalLeaveLog, setTotalLeaveLog] = useContext(LogContext);
  const [sortConfig, setSortConfig] = useState({
    key: "CreateDate",
    direction: "desc",
  });
  const [showAnimation, setShowAnimation] = useState(false);

  var memberId = auth.memberId;
  var isManager = auth.isManager;

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Member/IsBirthdayDate`, {
        MemberId: memberId,
      })
      .then(function (res) {
        if (res.data.Status === true) {
          if (res.data.Value === 1) {
            setShowAnimation(true);
          } else {
            setShowAnimation(false);
          }
        } else {
          setShowAnimation(false);
        }
      });
    axios
      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/GetLeaveList`, {
        MemberId: memberId,
      })
      .then(function (res) {
        if (res.data.Status === true && res.data.StatusCode === "OK") {
          setRows(
            res.data.LeaveLogList.map((leavelog) => {
              return {
                ...leavelog,
                CreateDate: moment(leavelog.CreateDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                StartDate: moment(leavelog.StartDate)
                  .format("DD-MM-YYYY")
                  .toString(),
                EndDate: moment(leavelog.EndDate)
                  .format("DD-MM-YYYY")
                  .toString(),
              };
            })
          );
          // setRows(res.data.LeaveLogList);
        } else {
          setRows([]);
        }
      });
  }, [changeColor]);
  const useStyles = makeStyles((theme) => ({
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  const handleSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      if (key === "MemberName") {
        return a.MemberName.localeCompare(b.MemberName, "tr", {
          sensitivity: "base",
        });
      }
      if (key === "LeaveType") {
        return a.LeaveType.localeCompare(b.LeaveType, "tr", {
          sensitivity: "base",
        });
      }
      if (key === "CreateDate" || key === "StartDate" || key === "EndDate") {
        const dateA = new Date(a[key].split("-").reverse().join("-"));
        const dateB = new Date(b[key].split("-").reverse().join("-"));
        return dateA - dateB;
      }
      if (key === "TotalLeaveTime") {
        return a.TotalLeaveTime - b.TotalLeaveTime;
      }
      return 0;
    });

    if (direction === "desc") {
      sorted.reverse();
    }
    setRows(sorted);
  };
  const getArrowIcon = (key) => {
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      return <ArrowDropUpIcon sx={{ color: "green !important" }} />;
    }
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      return <ArrowDropDownIcon sx={{ color: "green !important" }} />;
    }
    return <span />;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredData = rows.filter((row) => {
    return (
      row.MemberName.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.LeaveType.toLocaleLowerCase("tr-TR").includes(
        searchQuery.toLocaleLowerCase("tr-TR")
      ) ||
      row.StartDate.includes(searchQuery) ||
      row.EndDate.includes(searchQuery) ||
      row.CreateDate.includes(searchQuery) ||
      row.TotalLeaveTime.toString().includes(searchQuery)
      // row.Note.toString().includes(searchQuery)
    );
  });

  const handleChangeSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleDeleteRow = (id) => {
    // const updatedData = rows.filter((row) => row.id !== id);
    const updatedData = rows.filter((row) => row.LeaveId !== id);
    setRows(updatedData);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  return (
    <>
      {showAnimation === true && isManager === 1 ? (
        <ReactFloatingBalloons
          count={4}
          msgText="Happy Birthday !!"
          colors={["yellow", "purple", "red", "blue"]}
          popVolumeLevel={0}
        // speed={100}
        />
      ) : null}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <p align="left" style={{ fontSize: 25, padding: 20 }}>
          Gelen Talepler
        </p>
        <br></br>
        <br></br>
        <p
          align="right"
          style={{ fontSize: 13, paddingTop: 60, paddingRight: 20 }}
        >
          <b>Toplam Talep Sayısı: </b> {rows.length}
        </p>
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          label="Search"
          value={searchQuery}
          placeholder="Liste İçinde Ara"
          onChange={handleChangeSearch}
          variant="outlined"
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("CreateDate")}
                >
                  <b>Oluşturma Tarihi</b>
                  {getArrowIcon("CreateDate")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("MemberName")}
                >
                  <b>İzin İsteyen</b>
                  {getArrowIcon("MemberName")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("LeaveType")}
                >
                  <b>İzin Türü</b>
                  {getArrowIcon("LeaveType")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("StartDate")}
                >
                  <b>İzne Çıkacağı Tarih</b>
                  {getArrowIcon("StartDate")}
                </TableCell>
                <TableCell align="center" onClick={() => handleSort("EndDate")}>
                  <b>İzinden Döneceği Tarih</b>
                  {getArrowIcon("EndDate")}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("TotalLeaveTime")}
                >
                  <b>Toplam İzin Süresi (Gün)</b>
                  {getArrowIcon("TotalLeaveTime")}
                </TableCell>
                <TableCell align="center">
                  <b>Kullanıcı Notu</b>
                </TableCell>
                <TableCell align="center">
                  <b>Rapor</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const color =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? "yellow"
                      : row.LeaveStatus === "Onaylandı"
                        ? "green"
                        : "red";
                  const leaveId = row.LeaveId;
                  const title =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? "Onay Bekliyor"
                      : row.LeaveStatus === "Onaylandı"
                        ? "Onaylandı"
                        : "Reddedildi";
                  const checkIcon =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? 1
                      : row.LeaveStatus === "Onaylandı"
                        ? 0
                        : 1;
                  const closeIcon =
                    row.LeaveStatus === "Onay Bekliyor"
                      ? 1
                      : row.LeaveStatus === "Onaylandı"
                        ? 1
                        : 0;
                  const downloadIcon =
                    row.FileName !== null && row.FilePath !== null ? 1 : 0;
                  return (
                    <TableRow key={row.LeaveId}>
                      {/* <TableRow key={row.id}> */}
                      <TableCell>
                        <Tooltip title={title}>
                          <button
                            className="square"
                            style={{
                              backgroundColor: color,
                              width: 15,
                              height: 15,
                              borderRadius: "40%",
                            }}
                          ></button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {row.ImagePath === null ? (
                          <Avatar
                            alt="Profile Photo"
                            // src={`https://avatars.dicebear.com/api/initials/${row.MemberName}.svg`}
                            {...stringAvatar(`${row.MemberName}`)}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        ) :
                          <Avatar
                            alt="Profile Photo"
                            src={row.ImagePath}
                            style={{ height: "35px", width: "35px" }}
                          // className={classes.avatar}
                          />
                        }
                      </TableCell>
                      <TableCell align="center">{row.CreateDate}</TableCell>
                      <TableCell align="center">{row.MemberName}</TableCell>
                      <TableCell align="center">{row.LeaveType}</TableCell>
                      <TableCell align="center">{row.StartDate}</TableCell>
                      <TableCell align="center">{row.EndDate}</TableCell>
                      <TableCell align="center">{row.TotalLeaveTime}</TableCell>
                      <TableCell align="center">{row.Note}</TableCell>
                      <TableCell align="center">
                        {downloadIcon === 1 && (
                          <Tooltip title="Raporu İndir">
                            <Link
                              style={{ color: "gray" }}
                              to={
                                `${process.env.REACT_APP_SADETEAM_URL}` +
                                row.FilePath
                              }
                              //to={"C:/ceren/sade-iot-team" + (row.FilePath)}
                              download={row.FileName}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon />
                            </Link>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={6}>
                            <Grid item xs={4} sm={2}>
                              {checkIcon === 1 && (
                                <Tooltip title="Onay Ver">
                                  <IconButton
                                    onClick={() => {
                                      setChangeColor(0);
                                      setTotalLeaveLog(true);
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/LeaveRequest/UpdateLeaveLog`,
                                          {
                                            TotalLeaveTime: row.TotalLeaveTime,
                                            LeaveStatusId: 3,
                                            LeaveId: leaveId,
                                            Rejection_ApprovalManagerId: memberId,
                                            LeaveTypeId: row.LeaveTypeId
                                          }
                                        )
                                        .then((res) => {
                                          setChangeColor(3);
                                        });
                                    }}
                                  >
                                    <CheckOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              {closeIcon === 1 && (
                                <Tooltip title="Reddet">
                                  <IconButton
                                    onClick={() => {
                                      setChangeColor(0);
                                      setTotalLeaveLog(true);
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/LeaveRequest/UpdateLeaveLog`,
                                          {
                                            TotalLeaveTime: row.TotalLeaveTime,
                                            LeaveStatusId: 4,
                                            LeaveId: leaveId,
                                            Rejection_ApprovalManagerId: memberId,
                                            LeaveTypeId: row.LeaveTypeId
                                          }
                                        )
                                        .then((res) => {
                                          setChangeColor(4);
                                        });
                                    }}
                                  >
                                    <CloseOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                            <Grid item xs={4} sm={2}>
                              <Tooltip title="Talebi Sil">
                                <IconButton
                                  onClick={() => {
                                    Swal.fire({
                                      title: ` ${row.MemberName} Adlı Kullanıcın İzin Talebini Silmek İstediğinizden Emin Misiniz?`,
                                      showDenyButton: true,
                                      confirmButtonText: "Evet",
                                      denyButtonText: "Hayır",
                                      customClass: {
                                        actions: "my-actions",
                                        cancelButton: "order-1 right-gap",
                                        confirmButton: "order-2",
                                        denyButton: "order-3",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setChangeColor(0);
                                        setTotalLeaveLog(true);
                                        axios
                                          .post(
                                            `${process.env.REACT_APP_API_URL}/LeaveRequest/UpdateLeaveLog`,
                                            {
                                              LeaveStatusId: 5,
                                              LeaveId: leaveId,
                                              TotalLeaveTime: row.TotalLeaveTime,
                                              Rejection_ApprovalManagerId: memberId,
                                              LeaveTypeId: row.LeaveTypeId
                                            }
                                          )
                                          .then((res) => {
                                            setChangeColor(5);
                                            if (res.data.Status === true) {
                                              handleDeleteRow(leaveId);
                                              Swal.fire({
                                                icon: "success",
                                                title:
                                                  "İzin Talebi Silme İşlemi Başarılı",
                                              });
                                            } else {
                                              Swal.fire({
                                                icon: "warning",
                                                title: res.data.StatusCode,
                                              });
                                            }
                                          });
                                      }
                                    });
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
